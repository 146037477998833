import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { Fragment, useEffect, useState } from "react";
import { setEventSelected, setProceededToPayment, setRequestNotes, setRequestSuccess, setRequestType } from "../../store/User/UserSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { createCancelRequest } from "../../services/apiService";
import { Event } from "../../@types/event.d";
import { useHistory } from 'react-router-dom';
import { getAnalytics, logEvent } from "firebase/analytics";

const Footer: React.FC = (props: any) => {
    const { user } = useAuth0();
    const { eventSelected, agreementAccepted, proceededToPayment, cancelOrTransfer, requestType, requestNotes, requestSuccess } = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const history = useHistory();

    const createRequest = async () => {
        // setIsProcessing(true);
        try {
            const registationToCancel = JSON.parse(eventSelected) as Event;
            if (user && cancelOrTransfer) {
                var acctId = user['http://schemas.ccl.org/accounts/claims/account/id'];

                let success = await createCancelRequest(acctId,
                    {
                        eventKey: registationToCancel.eventKey,
                        eventName: registationToCancel.title,
                        startDate: registationToCancel.startDate,
                        endDate: registationToCancel.endDate,
                        requestType: requestType,
                        notes: requestNotes.trim()
                    }
                );
                if (success) {
                    // setRequestComplete(true);
                    dispatch(setRequestSuccess(true));
                    logEvent(getAnalytics(),
                        requestType + " Event",
                        {
                            "Event Name": registationToCancel.title,
                            "Event ID / Code": registationToCancel.programCode + " (" + registationToCancel.eventKey + ")",
                            "Event Location": (registationToCancel.city + ", " + registationToCancel.country),
                            "User": user['http://schemas.ccl.org/accounts/claims/account/analytics-id'] ?? "tracking_id"
                        }
            )       ;
                }
            }
        } catch (error) {
            alert("Your request could not be processed at this time. Please retry later.");
        }
    }

    const buttonDisabled = () => {
        if (requestType !== "" && requestNotes.trim() !== "" && agreementAccepted) {
            return false;
        }
        return true;
    }

    useEffect(() => {
    },[requestNotes, requestType])

    useEffect(() => {
        //We do not want back navigation to disturb the state, so we control it
        const unblock = history.block((location, action) => {
            if (action === 'POP') {
                if (history.location.pathname === '/') return;
                setShowModal(true);
                return false;
            }
        });
    
        return () => {
          unblock();
        };
    });

    return (
        <Fragment>
        {
            eventSelected !== "" && !proceededToPayment && (!requestSuccess) &&
                <div className='footer bg-white py-4 d-flex flex-lg-column fixed-bottom border-top' id='kt_footer' data-testid='primary-footer'>
                    <div className='container d-flex flex-column flex-md-row align-items-center justify-content-between pb-20'>
                        <button
                            data-testid='start_register_button'
                            className='btn btn-outline-primary btn-sm'
                            onClick={() => { 
                                if (agreementAccepted) {
                                    setShowModal(true);
                                } else {
                                    dispatch(setEventSelected(""));
                                }
                            }}
                        ><i style={{fontSize: "small"}} className="flaticon2-left-arrow"></i> Return to Home Page</button>

                        <button
                            data-testid='start_register_button'
                            className='btn btn-primary btn-sm'
                            disabled={!agreementAccepted}
                            onClick={() => dispatch(setProceededToPayment(true))}
                        >Accept and Proceed to Payment <i className="flaticon2-right-arrow"></i></button>
                    </div>
                </div>
        }
        {
            proceededToPayment || cancelOrTransfer && (!requestSuccess) &&
            <div className='footer bg-white py-4 d-flex flex-lg-column fixed-bottom border-top' id='kt_footer' data-testid='primary-footer'>
                <div className='container d-flex flex-column flex-md-row align-items-center justify-content-between pb-20'>
                    <button
                        data-testid='start_register_button'
                        className='btn btn-outline-primary btn-sm'
                        onClick={() => {
                            // if (!(cancelOrTransfer ? buttonDisabled() : !agreementAccepted)) {
                                setShowModal(true);
                            // } else {
                            //     dispatch(setEventSelected(""));
                            //     dispatch(setProceededToPayment(false));
                            // }
                        }}
                    ><i style={{fontSize: "small"}} className="flaticon2-left-arrow"></i> Return to Home Page</button>

                    <button
                        data-testid='start_register_button'
                        className={cancelOrTransfer ? 'btn btn-danger btn-sm' : 'btn btn-primary btn-sm'}
                        disabled={cancelOrTransfer ? buttonDisabled() : !agreementAccepted}
                        onClick={() => cancelOrTransfer ? createRequest() : dispatch(setProceededToPayment(true))}
                    >{cancelOrTransfer ? 'Request' : 'Submit Payment'} <i className="flaticon2-right-arrow"></i></button>
                </div>
            </div>
        }
        <div className='footer bg-white py-4 d-flex flex-lg-column fixed-bottom border-top' id='kt_footer' data-testid='primary-footer'>
            
                <div className='container d-flex flex-column flex-md-row align-items-center justify-content-between'>
                <div className='text-dark order-2 order-md-1'>
                    <span className='text-muted font-weight-bold mr-2'>©</span>
                    <a data-testid='link-ccl' href='https://www.ccl.org' target='_blank' rel='noreferrer' className='noto text-dark-75 text-hover-primary'>Center for Creative Leadership. All rights reserved</a>
                </div>
                <div className='nav nav-dark order-1 order-md-2 noto'>
                    <a data-testid='link-about' href='https://www.ccl.org/about-us/' target='_blank' rel='noreferrer' className='nav-link pr-3 pl-0'>About</a>
                    <a data-testid='link-privacy' href='https://www.ccl.org/policies/privacy-policy/' target='_blank' rel='noreferrer' className='nav-link px-3'>Privacy</a>
                    <a data-testid='link-contact' href='https://www.ccl.org/contact-us/' target='_blank' rel='noreferrer' className='nav-link px-3 pr-0'>Contact</a>
                    <a data-testid='link-help' href='https://regporthelp.ccl.org/home/' target='_blank' rel='noreferrer' className='nav-link pr-3 pr-0'>Need Help?</a>
                </div>
            </div>
        </div>
        <Modal
            show={showModal}
            backdrop='static'
            keyboard={false}
            size='sm'
            aria-labelledby='contained-modal-title-vcenter'
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title-vcenter' className="noto">
                    Are You Sure?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    history.location.pathname === "/event" ?
                    <Row className="noto pl-5 pr-5">
                        By leaving this page all information you have filled out will be discarded. 
                        <br/><br/>
                        You will not be registered for this event.
                    </Row>
                    :
                    <Row className="noto pl-5 pr-5">
                        By leaving this page all information you have filled out will be discarded. 
                        <br/><br/>
                        Your request for this event will not be submitted.
                    </Row>
                }
                
            </Modal.Body>
            <Modal.Footer>
                <Button
                    data-testid='clear_hold_button'
                    className='btn btn-sm btn-outline-primary'
                    onClick={() => {
                        setShowModal(false);  
                    }}>
                    Cancel
                </Button>
                <Button
                    data-testid='clear_hold_button'
                    className='btn btn-sm btn-danger'
                    onClick={() => {
                        setShowModal(false);
                        dispatch(setEventSelected(""));
                        dispatch(setProceededToPayment(false));
                    }}
                >
                    Go back to dashboard
                </Button>
            </Modal.Footer>
        </Modal>
    </Fragment>
    );
}

export default Footer;
