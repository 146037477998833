import { Fragment, useEffect, useState } from 'react';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { RootState } from '../store';
import { useAuth0 } from '@auth0/auth0-react';
import Form from 'react-bootstrap/Form';
import { setRequestNotes, setRequestType } from '../store/User/UserSlice';
import { getEventsByClientAndType } from '../services/apiService';

interface TransferCancelProps {
    displayForm?: boolean;
    onAgree?(e: any): void;
}

const Fees: React.FC = () => {
    return (
        <Fragment>
            <Card.Body style={{padding: "10px 40px"}} className='text-left'>
                <div className='noto font-weight-bolder pt-5 pb-5'>
                    Yes, you can transfer to another cohort or cancel your enrolment where necessary. Please note, fees may apply.
                </div>
                <Table style={{margin: "10px 10px"}}>
                    <Row
                        className={'noto'}
                    >
                        <Col xs='3' className={"border p-3 text-center font-weight-bolder"}>
                            Number of Days Before Programme
                        </Col>
                        <Col xs='3' className={"border-top border-bottom border-right p-3 text-center font-weight-bolder"}>
                            Replacement
                        </Col>
                        <Col xs='3' className={"border-top border-bottom border-right p-3 text-center font-weight-bolder"}>
                            Transfer Fee
                        </Col>
                        <Col xs='3'className={"border-top border-bottom border-right p-3 text-center font-weight-bolder"}>
                            Cancellation Fee
                        </Col>
                    </Row>
                    <Row
                        className={'noto'}
                    >
                        <Col xs='3' className={"border p-3 text-center"}>
                            More than 30 days
                        </Col>
                        <Col xs='3' className={"border-top border-bottom border-right p-3 text-center"}>
                            {`Yes (no fee)`}
                        </Col>
                        <Col xs='3' className={"border-top border-bottom border-right p-3 text-center"}>
                            {'$125'}
                        </Col>
                        <Col xs='3'className={"border-top border-bottom border-right p-3 text-center"}>
                            None
                        </Col>
                    </Row>
                    <Row
                        className={'noto'}
                    >
                        <Col xs='3' className={"border-left border-bottom border-right p-3 text-center"}>
                            15-30 days
                        </Col>
                        <Col xs='3' className={"border-bottom border-right p-3 text-center"}>
                            {`Yes (no fee)`}
                        </Col>
                        <Col xs='3' className={"border-bottom border-right p-3 text-center"}>
                            25% of tuition
                        </Col>
                        <Col xs='3'className={"border-bottom border-right p-3 text-center"}>
                            50% of tuition
                        </Col>
                    </Row>
                    <Row
                        className={'noto'}
                    >
                        <Col xs='3' className={"border-left border-bottom border-right p-3 text-center"}>
                            Less than 14 days
                        </Col>
                        <Col xs='3' className={"border-bottom border-right p-3 text-center"}>
                            Upon CCL Approval
                        </Col>
                        <Col xs='3' className={"border-bottom border-right p-3 text-center"}>
                            50% of tuition
                        </Col>
                        <Col xs='3'className={"border-bottom border-right p-3 text-center"}>
                            100% of tuition
                        </Col>
                    </Row>
                </Table>
            </Card.Body>
        </Fragment>
    )
}

const RequestForm: React.FC = () => {
    const { user } = useAuth0();
    const dispatch = useDispatch();
    const { agreementAccepted, eventSelected, eventTypes } = useSelector((state: RootState) => state.user);
    const [request, setRequest] = useState('');
    const [transferDate, setTransferDate] = useState('');
    const [requestText, setRequestText] = useState('');

    const [otherDates, setOtherDates] = useState<Array<string>>();
    const [loaded, setLoaded] = useState(false);

    const capitalize = (str: string) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    useEffect(()=>{
    },[agreementAccepted]);

    useEffect(() => {
        if (request !== '') {
            dispatch(setRequestType(request));
        } 
        dispatch(setRequestNotes(transferDate + ' ' + requestText));
    },[request, requestText, transferDate])

    useEffect(() => {
        async function getDates () {
            if (user) {
                let clientId = user['http://schemas.ccl.org/accounts/claims/client/id']
                let currEvent = JSON.parse(eventSelected ?? "");
                let currEventKey = currEvent.eventKey;
                let currEventType = eventTypes.slice().filter((eT) => eT.events.filter((eV) => eV.eventKey === currEvent.eventKey).length > 0)[0].programCode;
                let otherEvents = (await getEventsByClientAndType(clientId, currEventType)).events;

                currEvent = otherEvents.slice().filter((eV) => eV.eventKey === currEventKey)[0];
                otherEvents = otherEvents.slice().filter((eV) => eV.eventKey !== currEventKey);
                
                let dates: string[] = [];
                otherEvents.slice().filter((eX) => 
                    // eX.country === currEvent.country && 
                    eX.availableSeats > 0).map((eV) => (
                    dates.push(
                        moment(eV.startDate).format('MMM DD, YYYY') + ' - ' + moment(eV.endDate).format('MMM DD, YYYY') +
                        ' (' + capitalize(eV.city) + ', ' + capitalize(eV.country) + ')'
                    )
                ));
                setOtherDates(dates);
            }
        }
        getDates().then(() => setLoaded(true));
    },[user]);

    return (
        <Fragment>
            <Card
                data-testid='display_event_card'
                className='card-custom gutter-b'
                style={{marginBottom: 120}}
            >
                <Card.Header>
                    <div className='noto card-title font-weight-bolder text-left'>
                        Request Form
                    </div>
                </Card.Header>
                <div className='noto text-left pl-10 pt-10'>I am requesting</div>
                <Form.Control as="select" onChange={(e) => setRequest(e.target.value)} size='lg' style={{width: 500}} className='border ml-10 mt-5'>
                    <option value={''}>Please select an option</option>
                    <option value={'Transfer'}>To Transfer My Registration</option>
                    <option value={'Cancel'}>To Cancel My Registration</option>
                </Form.Control>
                {
                    request === 'Transfer' ?
                    <Fragment>
                        
                            {
                                (loaded && otherDates && otherDates.length > 0) &&
                                <Fragment>
                                    <div className='noto text-left pl-10 pt-10'>The Course Date I Would Like To Transfer To</div>
                                    <Form.Control as="select" onChange={(e) => setTransferDate(e.target.value)} size='lg' style={{width: 500}} className='border ml-10 mt-5'>
                                        <option value={''}>Please select a date</option>
                                        {
                                            otherDates.slice().filter(function(item, pos, self) {
                                                return self.indexOf(item) == pos;
                                            }).map((dt, index) => {
                                                return (
                                                    <option value={dt} key={index}>{dt}</option>
                                                )
                                            })
                                        }
                                    </Form.Control>
                                </Fragment>
                                
                            }
                            <div className='noto text-left pl-10 pt-10'>If you do not see your preferred dates listed, please put information for your transfer here</div>
                            <Form.Control onChange={(e) => setRequestText(e.target.value)} as="textarea" rows={3} style={{width: 500}} className='border ml-10 mt-5'/>
                    </Fragment>
                    : request === 'Cancel' &&
                    <Fragment>
                        <div className='noto text-left pl-10 pt-10'>Reason for cancellation</div>
                        <Form.Control onChange={(e) => setRequestText(e.target.value)} as="textarea" rows={3} style={{width: 500}} className='border ml-10 mt-5'/>
                    </Fragment>
                }
                
                <div className='noto text-left p-10'>Someone will contact you at {user?.email} to confirm your request.</div>
            </Card>
        </Fragment>
    )
};

const TransferCancelDetails: React.FC<TransferCancelProps> = ({ displayForm, onAgree }) => {
    const { agreementAccepted } = useSelector((state: RootState) => state.user);

    useEffect(()=>{
    },[agreementAccepted])

    return (
        <Fragment>
            <Card
                data-testid='display_event_card'
                className='card-custom gutter-b'
                style={{marginBottom: 30}}
            >
                <Card.Header>
                    <div className='noto card-title font-weight-bolder text-left'>
                        Enrolment Transfer and Cancellation
                    </div>
                </Card.Header>
                <Fees />
                { 
                    displayForm &&
                        <Row style={{padding: "8px 53.5px", marginBottom: 20}}>
                            <div className="noto">
                                <div className="border p-5" style={{borderRadius: 10}}>
                                    <Form.Check type="checkbox" checked={agreementAccepted} onChange={(e) => onAgree && onAgree(e.target.checked)} 
                                        label="&nbsp;&nbsp;&nbsp;* I acknowledge and accept the applicable fees related to updating my registration" 
                                    />
                                </div>
                            </div>
                        </Row>
                }
            </Card>
            {
                displayForm && <RequestForm />
            }
        </Fragment>
    )
};

export default TransferCancelDetails;
